<template>
  <div>
    <div class="h-screen md:h-75vh lg:h-screen flex items-center justify-center">
      <div
        class="w-full md:w-1024 py-14 mx-auto flex flex-col md:flex-row items-center md:items-start justify-end"
      >
        <img
          class="w-36 md:w-64"
          src="@/assets/images/icons/Group1608.png"
          alt="Group1608"
        />
        <div class="w-full md:w-8/12 px-4 md:px-0 mt-10">
          <h3 class="text-2xl font-black">
            neo Student Certificate Verification
          </h3>
          <p class="w-10/12 py-4">
            Because our students work hard to achieve their certified English
            skills, neo works hard to ensure others don't take the easy road to
            certification. <br>
            Enter the universal ID certificate code (UDID) on your CEFR certificate:
          </p>
          <input
            class="w-8/12 p-2 rounded-sm border border-softGrey"
            type="text"
            v-model="cert_id"
            placeholder="Example: Sr-1F-A04f-Xxxx-Xx"
          />
          <div class="text-red text-sm">{{verifStatus}}</div>
          <RoundedButtonVue
            v-if="cert_id == ''"
            title="Verify"
            bgColor="bg-grey"
            textColor="text-white"
            width="w-max"
            class="mt-4 cursor-not-allowed"
          />
          <div v-else>
            <RoundedButtonVue
              v-if="!toggleLoader"
              title="Verify"
              bgColor="bg-primary"
              textColor="text-white"
              width="w-max"
              class="mt-4"
              @click.native="getCert"
            />
            <LoaderVue v-else />
          </div>
          <div
            class="w-full md:w-8/12 px-4 md:px-0 flex flex-col justify-end items-end"
          >
            <!-- <div class="w-full flex justify-end items-center">
              <span class="text-sm">Certificate Found:</span>
              <div class="w-8/12 ml-4 bg-softGrey rounded-sm p-2">
                {{ cert_id }}
              </div>
            </div> -->
            <div class="w-full flex justify-end">
              <!-- <RoundedButtonVue
                title="View"
                bgColor="bg-primary"
                textColor="text-white"
                width="w-max"
                class="mt-4 mr-4"
                @click.native="openCertificationModal"
              /> -->
              <!-- <RoundedButtonVue
                v-if="gettersCert"
                title="Download"
                bgColor="bg-softGreen"
                textColor="text-white"
                width="w-max"
                class="mt-4"
                @click.native="getPdf"
              /> -->
            </div>
          </div>
          <!-- <div v-if="gettersCert != false" class="h-full mt-16 flex flex-col items-center justify-start">
            <img class="h-48" :src="gettersCert" alt="certificate image" />
          </div> -->
        </div>
      </div>
    </div>
    <!-- view certificate image -->
    <basic-modals-vue
      :show="showViewCertificationModal"
      @update:show="showViewCertificationModal = $event"
      class="z-10"
    >
      <CloseIconVue
        :width="20"
        :height="20"
        iconColor="#111"
        class="items-center absolute top-10 md:top-7 right-10 md:right-20 cursor-pointer"
        @click.native="closeModals"
      />
      <div v-if="gettersCert.status == 200" class="mt-16 flex flex-col items-center justify-start">
        <img class="w-full lg:w-4/12" :src="gettersCert.data.data.image" alt="certificate image" />
      </div>
      <div v-if="gettersCert.status == 200" class="w-max mt-8 mx-auto">
        <a
          class="py-3 px-10 bg-softGreen text-white rounded-full text-center font-semibold cursor-pointer"
          :href="gettersCert.data.data.pdf"
          target="_blank"
          download
          >Download PDF</a
        >
      </div>
    </basic-modals-vue>
    <!-- view certificate image end -->

    <!-- view certificate modals -->
    <!-- <BasicModalsVue
      :show="showViewCertificationModal"
      @update:show="showViewCertificationModal = $event"
      class="z-10"
    >
      <div
        class="w-full md:w-1024 px-4 md:px-0 flex items-center justify-end cursor-pointer"
      >
        <CertificateIconVue
          class="mr-2"
          :height="20"
          :width="20"
          iconColor="#29396A"
        />
        <span class="text-primary">Download Certificate</span>
      </div>
      <CloseIconVue
        :width="20"
        :height="20"
        iconColor="#111"
        class="items-center absolute top-20 md:top-7 right-10 md:right-20 cursor-pointer"
        @click.native="showViewCertificationModal = false"
      />
      <div class="h-full mt-16 flex flex-col items-center justify-start">
        <div
          class="md:w-6/12 flex flex-col md:flex-row items-center justify-between"
        >
          <div class="">
            <img
              class="h-12"
              src="@/assets/images/logo_neo.svg"
              alt="logo_neo"
            />
            <h3 class="mt-6 mb-1 text-primary text-2xl font-bold">
              Statement Of Results
            </h3>
            <h4>English Assessment Test</h4>
            <h5 class="mt-1 text-sm">
              Aligned with the Common European Framework of Reference.
            </h5>
          </div>
          <div>
            <div class="w-max text-center py-4 px-6 bg-softGrey rounded-md">
              <h3 class="text-primary text-xl font-bold">CEFR</h3>
              <h4 class="text-xs font-bold text-primary">LEVEL</h4>
              <img
                class="mt-2 h-16"
                src="@/assets/images/planets/A1.png"
                alt="A1"
              />
            </div>
          </div>
        </div>
        <div
          class="w-full md:w-6/12 mt-10 flex flex-col md:flex-row justify-between"
        >
          <div class="w-full md:w-8/12 mr-4 flex flex-col">
            <span class="text-xs">Name:</span>
            <div class="font-bold bg-softGrey rounded-md mt-1 p-2">Nina12</div>
          </div>
          <div class="w-full md:w-4/12 flex flex-col">
            <span class="text-xs">Exam Date:</span>
            <div class="font-bold bg-softGrey rounded-md mt-1 p-2">
              14-Feb-23
            </div>
          </div>
        </div>
        <div
          class="w-full md:w-6/12 mt-4 flex flex-col md:flex-row justify-between"
        >
          <div class="w-full flex flex-col">
            <span class="text-xs">Similar to:</span>
            <div class="flex justify-between">
              <div
                class="w-4/12 font-bold text-center bg-softGrey rounded-md mt-1 p-2"
              >
                TOEFL 0-54
              </div>
              <div
                class="w-4/12 mx-4 font-bold text-center bg-softGrey rounded-md mt-1 p-2"
              >
                IELTS 0-3
              </div>
              <div
                class="w-4/12 font-bold text-center bg-softGrey rounded-md mt-1 p-2"
              >
                TOEIC 0-400
              </div>
            </div>
          </div>
        </div>
        <div class="w-full md:w-6/12 mt-10 pb-20 flex flex-col justify-between">
          <div
            class="w-full pb-2 text-primary font-bold border-b border-softGrey flex"
          >
            <div class="w-6/12 mr-2">Skill</div>
            <div class="w-6/12">Proficiency</div>
          </div>
          <div
            class="flex py-5 border-b border-softGrey items-center justify-between"
            v-for="(data, i) in skillProficiency"
            :key="i"
          >
            <div class="w-6/12 mr-2 text-sm">
              <h5 class="font-bold">{{ data.name }}</h5>
              <p>
                {{ data.caption }}
              </p>
            </div>
            <ProficiencyLevelComponentVue
              :proficiency="data.value"
              class="w-6/12"
            />
          </div>
        </div>
      </div>
    </BasicModalsVue> -->
    <!-- view certificate modals end -->
  </div>
</template>

<script>
import RoundedButtonVue from "../components/buttons/RoundedButton.vue";
import BasicModalsVue from "../components/modals/BasicModals.vue";
import ProficiencyLevelComponentVue from "../components/proficiencylevel/ProficiencyLevelComponent.vue";
import CertificateIconVue from "../components/svgicons/CertificateIcon.vue";
import CloseIconVue from "../components/svgicons/CloseIcon.vue";
import LoaderVue from "../components/loader/Loader.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    RoundedButtonVue,
    BasicModalsVue,
    CloseIconVue,
    CertificateIconVue,
    ProficiencyLevelComponentVue,
    LoaderVue,
  },
  data() {
    return {
      showCertificationModal: false,
      showCertificateResult: false,
      showViewCertificationModal: false,
      cert_id: "",
      toggleLoader: false,
      verifStatus: "",
      skillProficiency: [
        {
          name: "Listening",
          caption:
            "Measures ability to listen, understand and respond to the language expected at this CEFR level.",
          value: 5,
        },
        {
          name: "Social Interaction",
          caption:
            "Measures ability to participate in English conversations and discussions as expected for the CEFR level.",
          value: 6,
        },
        {
          name: "Reading",
          caption:
            "Measures comprehension and retention of information read as expected for the CEFR level.",
          value: 5,
        },
        {
          name: "Grammar",
          caption:
            "Measures mastery of grammatical structures and propoer usage of language in spoken and written form as expected for the CEFR level.",
          value: 6,
        },
        {
          name: "Vocabulary",
          caption:
            "Measures command of essential function and content words in real life situations both spoken and written for the CEFR Level.",
          value: 8,
        },
      ],
    };
  },
  methods: {
    closeModals() {
      this.showViewCertificationModal = false;
      this.toggleLoader = false;
      this.cert_id = "";
      this.$store.commit("moduleGetCertification/clearState");
    },
    openCertificationModal() {
      this.showViewCertificationModal = true;
    },
    getCert() {
      this.toggleLoader = true;
      this.$store.dispatch("moduleGetCertification/getCertification", {
        cert_id: this.cert_id,
      });
    },
  },
  computed: {
    ...mapGetters("moduleGetCertification", {
      gettersCert: "gettersCert",
    }),
  },
  watch: {
    gettersCert(i) {
      if (i.status == 200) {
        this.openCertificationModal();
        this.toggleLoader = false;
        this.verifStatus = "";
      } else {
        this.toggleLoader = false;
        this.verifStatus = i.message
      }
    },
  },
};
</script>

<style>
</style>